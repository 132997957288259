
import { mypartner } from "@/api/supplier.js";
import api from "../../vue.config"
export const mixins1 = {
  data() {
    const valiedateCom = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入采购商名称'))
      }
      return cb()
    }
    const valiedateLin = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入采购商联系人'))
      } else if (!/[\u4e00-\u9fa5_a-zA-Z]+$/.test(val)) {
        return cb(new Error('请输入正确的联系人格式'))
      }
      return cb()
    }
    const valiedateTel = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入电话号码'))
      } else if (!/^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/.test(val))
        return cb(new Error('请输入正确的电话号码格式'))
      return cb()
    }
    const valiedateEmail = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入邮箱账号'))
      } else if (!/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(val))
        return cb(new Error('请输入正确的邮箱账号格式'))
      return cb()
    }
    const valiedateYear = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入合作年限'))
      }
      return cb()
    }
    return {
      lunboIndex: 0,
      prevImg: require('@/assets/img/prev.png'),
      nextImg: require('@/assets/img/next.png'),
      // jpg: 'http://192.168.100.66:8081/',
      jpg: 'https://www.o2c-scf.com/api',
      applyDateChoose: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      applyDate: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      nameWidth: 320,
      OrderNoWidth: 300,
      width180: 160,
      width150: 150,
      width120: 120,
      width138: 138,
      // 图例大小
      legendWidth: 14,
      // 图例间隔
      lengendJg: 20,
      // 百分比字体
      pieFontSize: 12,
      // 标题字体
      pieFontSize1: 14,
      gridbottom: '21%',
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      // 额外的伙伴弹框
      applyFinancingDialog1: false,
      rules1: {
        coreEnterpriseName: [
          { required: true, validator: valiedateCom, trigger: 'blur' }
        ],
        contacts: [
          { required: true, validator: valiedateLin, trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: valiedateTel, trigger: 'blur' }
        ],
        email: [
          { required: true, validator: valiedateEmail, trigger: 'blur' }
        ],
        age: [
          { required: true, validator: valiedateYear, trigger: 'blur' }
        ]
      },
      addPartner: {
        coreEnterpriseId: '',
        coreEnterpriseName: '',
        contacts: '',
        phone: '',
        email: '',
        age: '',
        suppDesc: ''
      },
    }
  },
  created() {
    // this.jpg = api.devServer.proxy['/api'].target
    this.jpg = 'https://www.o2c-scf.com/api'
    // this.jpg = 'http://192.168.100.88:9091'
    // this.jpg = 'http://180.169.235.162:8085/appo2fapi'
    // console.log(api.devServer.proxy['/api'].target)
  },
  mounted() {
    // console.log(this.$refs.lunbo.children.length)
    if (this.$refs.lunbo) {
      this.lunboIndex = this.$refs.lunbo.children.length
    }
  },
  methods: {
    direction(direction) {
      if (direction == 'next') {
        if (this.index != (this.lunboIndex - 5)) {

          this.index++
          if (window.document.body.clientWidth > 1664) {
            this.$refs.lunbo.style.transform = 'translate3d(-' + 276 * this.index + 'px, 0px, 0px)'
          } else {
            this.$refs.lunbo.style.transform = 'translate3d(-' + 185 * this.index + 'px, 0px, 0px)'
          }
          this.$refs.lunbo.style.transition = '1s'
        }
      } else {
        if (this.index != 0) {
          this.index--
          if (window.document.body.clientWidth > 1664) {
            this.$refs.lunbo.style.transform = 'translate3d(-' + 276 * this.index + 'px, 0px, 0px)'
          } else {
            this.$refs.lunbo.style.transform = 'translate3d(-' + 185 * this.index + 'px, 0px, 0px)'
          }
        }
      }
    },
    open() {
      this.applyFinancingDialog1 = true
      this.$nextTick(() =>
        this.$refs.addPartner.resetFields()
      )
    },
    async mypartner() {
      let data = await mypartner(this.addPartner)
      if (data.code == 200) {
        this.getPurchaserList()
      } else if (data.code == 100) {
        this.$message({
          message: data.msg,
          type: 'warning'
        });
      }
    },
    applyFinancingDialogFalse() {
      this.$refs.addPartner.validate((valid) => {
        if (valid) {
          this.mypartner()
          this.applyFinancingDialog1 = false
        } else {
          return false;
        }
      });
    },
    handleSelect(item) {
      console.log(item)
    },
    querySearch(queryString, cb) {
      this.getSupplierName().then(() => {
        console.log("queryString",queryString)
        var restaurants = this.dataname;
        // console.log('11',restaurants)
        // var results=[]
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // if (restaurants) {
        //   results = queryString ? restaurants : [];
        // }
        cb(results);
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        console.log(restaurant.value)
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase())!==-1);
        // return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    onPageChange(val) {
      console.log(val)
      this.page.pageNo = val
      this.getDate()
    },
    tableWidth() {
      if (document.body.clientWidth > 1664) {
        this.nameWidth = 320
        this.width120 = 120
        this.width138 = 138
        this.width148 = 148
        this.width180 = 160
        this.width150 = 150
        this.comapnyWidth = 220
        this.OrderNoWidth = 300
        this.pieFontSize = 12
        this.pieFontSize1 = 14
        this.legendWidth = 14
        this.lengendJg = 20
        this.width170 = 170
        this.dateWidth = 120
        this.width140 = 134
        this.gridbottom = '21%'
        this.width147 = 147
        this.width190 = 175
      } else {
        this.pieFontSize = 10
        this.pieFontSize1 = 12
        this.nameWidth = 230
        this.width120 = 80
        this.width138 = 120
        this.width148 = 120
        this.width180 = 110
        this.width150 = 100
        this.OrderNoWidth = 200
        this.legendWidth = 14
        this.lengendJg = 13
        this.width170 = 125
        this.dateWidth = 100
        this.width120 = 94
        this.width140 = 94
        this.gridbottom = '28%'
        this.width147 = 98
        this.width190 = 150
      }
    },
    // 可切换的
    riskEchart2(fontSize, echartsWidth) {
      this.myChart = this.$echarts.init(this.$refs.echarts1)
      let that = this
      const option = {
        tooltip: {
          trigger: 'axis',
          extraCssText: 'box-shadow: 0px 4px 12px 0px rgba(7, 72, 64, 0.29);border-radius:10px;',
          textStyle: {
            color: 'inherit',
            fontSize: fontSize
          },
          axisPointer: {
            type: 'none',
            label: {
            }
          },
          formatter: function (params) {
            var tar = params[1];
            tar.value = that.filter(tar.value)
            if (tar.name == '未邀约数') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '笔' + '</span>';
            }
            if (tar.name == "已邀约数") {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '笔' + '</span>';
            }
            if (tar.name == '未邀约发票' || tar.name == '未申请融资发票') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '张' + '</span>';
            }
            if (tar.name == "已邀约发票" || tar.name == "已申请融资发票") {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '张' + '</span>';
            }
            if (tar.name == '未逾期单数') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '单' + '</span>';
            }
            if (tar.name == '逾期单数') {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '单' + '</span>';
            }
            if (tar.name == '逾期金额') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '元' + '</span>';
            }
            if (tar.name == '未逾期金额') {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '元' + '</span>';
            }
            if (tar.name == '未放款单数') {
              return '<span style="color:#7c969b;">' + tar.name.substr(0, 3) + tar.value + '单' + '</span>';
            }
            if (tar.name == "已放款单数") {
              return '<span style="color:#339c9b;">' + tar.name.substr(0, 3) + tar.value + '单' + '</span>';
            }
            if (tar.name == '已签约单数') {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '单' + '</span>';
            }
            if (tar.name == '未签约单数') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '单' + '</span>';
            }
          }
        },
        grid: {
          top: '20%',
          left: '11%',
          right: '1%',
          bottom: '20%'
        },
        xAxis: {
          type: 'value',
          // splitNumber: 10,
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#92A2BC'
            },

          },
          nameTextStyle: {
            fontFamily: 'Microsoft YaHei'
          },
          axisLabel: {
            fontSize: fontSize
          },
        },
        yAxis: {
          type: 'category',
          data: [
            {
              value: this.list1[1].name,
              textStyle: {
                color: '#7c969b',//坐标值得具体的颜色
              }
            },
            {
              value: this.list1[0].name,
              textStyle: {
                color: '#339C9B',//坐标值得具体的颜色
              }
            },
          ],
          axisLabel: {
            fontSize: fontSize
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: '#92A2BC' //坐标线的颜色
            },
          },
          nameTextStyle: {
            fontFamily: 'Microsoft YaHei'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            stack: 'Total',
            data: [0, 0]
          },
          {
            type: 'bar',
            stack: 'Total',
            data: [
              {
                value: this.list1[1].value,
                itemStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#cfdee1'
                    },
                    {
                      offset: 1, color: '#7c969b'
                    }
                    ],
                    globalCoord: false
                  },
                  shadowBlur: 10,
                  shadowColor: 'rgba(45, 62, 60, 0.2)',
                  shadowOffsetX: 6,
                  shadowOffsetY: 10,
                },
              },
              {
                value: this.list1[0].value,
                itemStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#7be0df'
                    },
                    {
                      offset: 1, color: '#339c9b'
                    }
                    ],
                    globalCoord: false
                  },
                  shadowBlur: 10,
                  shadowColor: 'rgba(12, 79, 71, 0.2)',
                  shadowOffsetX: 6,
                  shadowOffsetY: 10,
                }
              }
            ],
            barWidth: echartsWidth,
            itemStyle: {
              normal: {
                barBorderRadius: [0, 100, 100, 0],
              }
            }
          },
        ]
      }
      this.myChart.setOption(option, true)
      this.myChart.resize()
    },
    // 三个条状的
    riskEchart1(fontSize, echartsWidth) {
      this.myChart = this.$echarts.init(this.$refs.echarts1)
      let that = this
      const option = {
        tooltip: {
          trigger: 'axis',
          extraCssText: 'box-shadow: 0px 4px 12px 0px rgba(7, 72, 64, 0.29);',
          axisPointer: {
            type: 'none',
            label: {
              lineHeight: 30
            }
          },
          formatter: function (params) {
            var tar = params[1];
            tar.value = that.filter(tar.value)
            if (tar.name == '已还款金额' || tar.name == '已回款总额') {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '元' + '</span>';
            }
            if (tar.name == '未还款金额' || tar.name == '待回款总额') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '元' + '</span>';
            }
            if (tar.name == '逾期金额' || tar.name == '逾期总额') {
              return '<span style="color:#ff9b15;">' + tar.name + ':' + tar.value + '元' + '</span>';
            }
            if (tar.name == '已还款单数') {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '单' + '</span>';
            }
            if (tar.name == '未还款单数') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '单' + '</span>';
            }
            if (tar.name == '逾期单数') {
              return '<span style="color:#ff9b15;">' + tar.name + ':' + tar.value + '单' + '</span>';
            }
            if (tar.name == '已邀约发票') {
              return '<span style="color:#339c9b;">' + tar.name + ':' + tar.value + '张' + '</span>';
            }
            if (tar.name == '未邀约发票') {
              return '<span style="color:#7c969b;">' + tar.name + ':' + tar.value + '张' + '</span>';
            }
          }
        },
        grid: {
          top: '20%',
          left: '10%',
          right: '1%',
          bottom: '20%'
        },
        xAxis: {
          type: 'value',
          splitNumber: 10,
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#92A2BC'
            },
          },
          nameTextStyle: {
            fontFamily: 'Microsoft YaHei'
          },
          axisLabel: {
            align: 'right',
            fontSize: fontSize
          }
        },
        yAxis: {
          type: 'category',
          data: [
            {
              value: this.list1[2].name,
              textStyle: {
                color: '#ff9b15',//坐标值得具体的颜色
              }
            },
            {
              value: this.list1[1].name,
              textStyle: {
                color: '#7c969b',//坐标值得具体的颜色
              }
            },
            {
              value: this.list1[0].name,
              textStyle: {
                color: '#339C9B',//坐标值得具体的颜色
              }
            },
          ],
          axisLabel: {
            fontSize: fontSize
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: '#92A2BC' //坐标线的颜色
            },
          },
          nameTextStyle: {
            fontFamily: 'Microsoft YaHei'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            stack: 'Total',
            data: [0, 0, 0]
          },
          {
            type: 'bar',
            stack: 'Total',
            data: [
              {
                value: this.list1[2].value,
                itemStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#ffedd4'
                    },
                    {
                      offset: 1, color: '#ff9b15'
                    }
                    ],
                    globalCoord: false
                  },
                  shadowBlur: 12,
                  shadowColor: 'rgba(91, 80, 64, 0.2)',
                  shadowOffsetX: 6,
                  shadowOffsetY: 10,
                },
              },
              {
                value: this.list1[1].value,
                itemStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#cfdee1'
                    },
                    {
                      offset: 1, color: '#7c969b'
                    }
                    ],
                    globalCoord: false
                  },
                  shadowBlur: 12,
                  shadowColor: 'rgba(45, 62, 60, 0.2)',
                  shadowOffsetX: 6,
                  shadowOffsetY: 10,
                },
              },
              {
                value: this.list1[0].value,
                itemStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#7be0df'
                    },
                    {
                      offset: 1, color: '#339c9b'
                    }
                    ],
                    globalCoord: false
                  },
                  shadowBlur: 12,
                  shadowColor: 'rgba(12, 79, 71, 0.2)',
                  shadowOffsetX: 6,
                  shadowOffsetY: 10,
                }
              }
            ],
            barWidth: echartsWidth,
            itemStyle: {
              normal: {
                barBorderRadius: [0, 100, 100, 0],
              }
            }
          },
        ]
      }
      this.myChart.setOption(option, true)
      this.myChart.resize()
    },
    filter(num) {
      const newNum = parseInt(num)
      if (newNum) {
        if ((num + '').trim() == '') {
          return ''
        }
        if (String(num).indexOf(',') == -1) {
          num = parseFloat(num)
          num = num.toFixed(2)
          num = parseFloat(num)
          num = num.toLocaleString()
        } else {
          num = num.replace(/,/gi, '')
          num = parseFloat(num).toLocaleString()
        }
      }
      return num
    },
    filterOut(num) {
      console.log(num)
      if (num == 0 || num == null) {
        return num
      }
      else{
        num = num.split(',')
        let i = ''
        num.forEach(item => {
          i = i + item
        });
        return i
      }
     
    },
    filterOuts(num) {
      if (num == 0 || num == null) {
        return num
      }
      else {

        num = num.split(",")
        let i = ''
        num.forEach(item => {
          i = i + item
        });
        return i
      }
    },
    top() {
      this.$nextTick(function () {
        window.scrollTo(0, 0)
      })
    },
    echartsbar(data, tit, yname, pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      const option = {
        title: {
          text: tit,
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: 12
            },
            formatter: function (value) {
              if ((value.length > 5) && (tit != '逾期分布')) {
                return value.slice(0, 5) + '..'
              } else {
                return value
              }
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: function (value) {
              if (!yname) {
                return value + '%'
              }
              else if (value.toString().length < 6) {
                return value.toString()
              } else {
                return value.toString().slice(0, 5) + '...'
              }
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: yname,
          nameTextStyle: {
            lineHeight: yLineHeight
          },
        },
        grid: {
          left: '10%',
          right: '2%',
          top: '18%',
          bottom: gridbottom,
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (!yname) {
              return params['value'] + '%'
            } else {
              if (params.value > 1) {
                return params['value'] + yname.substr(0, 1)
              } else {
                return params['value'] + yname.substr(0, 1)
              }
            }
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: data
          }
        ]
      }
      this.fourChart.setOption(option)
      this.fourChart.resize()
    },
  }
}