module.exports = {
  //* 区分打包环境与开发环境
  assetsDir: 'static',
  outputDir: 'dist',
  parallel: false,
  publicPath: process.env.NODE_ENV == "development" ? '/' : './',
  // publicPath: '/',


  chainWebpack: config => {
    config
      .plugin('html')
      .tap(args => {
        args[0].title = '商易融供应链管理平台'
        return args
      })
  },
  configureWebpack: () => { },

  devServer: {
    port: 8083,
    proxy: { // 设置代理
      '/devapi': {
        // target: 'http://192.168.100.101:8910/',  // 开发
        // target: 'http://192.168.100.136:8081/', // 开发
        target: 'http://192.168.100.40:9091/', // 开发
        // target: 'http://192.168.100.153:8081/', // 开发
        // target: 'http://192.168.100.160:8081/', // 开发
        // target: 'http://192.168.100.66:8081/', // 开发

        changeOrigin: true, // 是否允许跨域
        pathRewrite: { // 重写路径 
          '^/devapi': ''
        }
      },
      '/test2': {
        target: 'http://52.81.97.167:8088', // 开发
        changeOrigin: true, // 是否允许跨域
        pathRewrite: { // 重写路径 
          '^/test2': ''
        }
      },
      '/api': {
        // target: 'http://47.102.122.233:8081', //服务器
        target: 'http://47.103.58.191:9091', //服务器
        // target: 'http://180.169.235.162:9091', // 开发‘’
        changeOrigin: true,
        pathRewrite: { // 重写路径
          '^/api': ''
        }
      },
    },
  },
  // 第三方插件配置
  pluginOptions: {
    // ...
  }
}